class DashboardService {
  constructor() {}
  resources = () => ({
    dashboard: {
      uri: `/v1/unidadcampamento/dashboard`,
      method: ['get']
    }
  });
}

export default DashboardService;
