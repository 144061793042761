<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-row>
      <v-spacer></v-spacer>
      <v-col lg="1" md="1" sm="2" class="my-2 col-2">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-4" v-on="on" fab dark small color="primary" @click="exportar()">
              <v-icon small dark>fas fa-arrow-alt-circle-down</v-icon>
            </v-btn>
          </template>
          <span>Exportar Data</span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="12">
        <BaseCard v-for="(sector, index) in sectores" :key="index" :heading="sector.sector">
          <v-row>
            <v-col cols="12" lg="3" sm="6" class="py-0 mb-7">
              <v-card>
                <v-card-text class="pa-5">
                  <div class="d-flex align-center">
                    <v-btn color="info" class="elevation-0" fab dark>
                      <v-icon>fas fa-boxes</v-icon>
                    </v-btn>
                    <div class="ml-2 mr-1">
                      <h2 class="font-weight-light">{{ sector.total }}</h2>
                      <h5 class="body-1 font-weight-regular">Total Camas</h5>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3" sm="6" class="py-0 mb-7">
              <v-card>
                <v-card-text class="pa-5">
                  <div class="d-flex align-center">
                    <v-btn color="green" class="elevation-0" fab dark>
                      <v-icon>fas fa-check</v-icon>
                    </v-btn>
                    <div class="ml-2 mr-1">
                      <h2 class="font-weight-light">{{ sector.habilitado + ' - ' + sector.phabilitado }}</h2>
                      <h5 class="body-1 font-weight-regular">Total Habilitado</h5>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3" sm="6" class="py-0 mb-7">
              <v-card>
                <v-card-text class="pa-5">
                  <div class="d-flex align-center">
                    <v-btn color="#FFAB00" class="elevation-0" fab dark>
                      <v-icon>fas fa-bed</v-icon>
                    </v-btn>
                    <div class="ml-2 mr-1">
                      <h2 class="font-weight-light">{{ sector.ocupado + ' - ' + sector.pocupado }}</h2>
                      <h5 class="body-1 font-weight-regular">Total Ocupado</h5>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3" sm="6" class="py-0 mb-7">
              <v-card>
                <v-card-text class="pa-5">
                  <div class="d-flex align-center">
                    <v-btn color="red" class="elevation-0" fab dark>
                      <v-icon>fas fa-window-close</v-icon>
                    </v-btn>
                    <div class="ml-2 mr-1">
                      <h2 class="font-weight-light">{{ sector.deshabilitado + ' - ' + sector.pdeshabilitado }}</h2>
                      <h5 class="body-1 font-weight-regular">Total Deshabilitado</h5>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <DashboardCard :bloques="sector.bloques"></DashboardCard>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DashboardService from '../services/DashboardService';
import { decryptAES } from '@/components4x/utils/utils4x';
export default {
  name: 'DashboardCampamento',

  data: () => ({
    page: {
      title: 'Dashboard'
    },
    breadcrumbs: [
      {
        text: 'Dashboard',
        disabled: true
      },
      {
        text: 'Campamento',
        disabled: true
      }
    ],
    dashboardService: null,
    sectores: []
  }),
  components: {
    DashboardCard: () => import('../components/DashboardCard')
  },
  methods: {
    async iniciaDataSectores() {
      this.sectores = [];
      const res = await this.dashboardService.get().execResource('dashboard', {
        idempresa: decryptAES(localStorage.getItem('emp'))
      });
      this.sectores = res;
    },
    exportar() {
      const token = localStorage.getItem('token');
      const empresa = decryptAES(localStorage.getItem('emp'));
      const url = `http://165.227.60.116:8005/api/v1/unidadcampamento/`;
      const uri = `excelrptdashboardcampamento?idempresa=${empresa}&token=${token}`;
      window.location.href = `${url}${uri}`;
    }
  },
  async mounted() {
    this.dashboardService = this.$httpService(new DashboardService(), this);
    this.iniciaDataSectores();
  }
};
</script>
